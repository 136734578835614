import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login')
  },
  {
    path: '/',
    name: '首页',
    redirect: '/system',
    component: () => import('../views/Manage'),
    children: [
      {
        path: '/system',
        name: '系统管理',
        redirect: '/system/config',
        component: () => import('../views/system/index'),
        children: [
          {
            path: 'config',
            name: '网站配置',
            component: () => import('../views/system/system')
          },
          {
            path: 'file',
            name: '媒体中心',
            component: () => import('../views/system/file')
          },
          {
            path: 'admLog',
            name: '日志管理',
            component: () => import('../views/system/admLog')
          },
        ],
      },
      {
        path: '/home',
        name: '首页设置',
        redirect: '/home/banner',
        component: () => import('../views/home/index'),
        children: [
          {
            path: 'banner',
            name: '轮播图管理',
            component: () => import('../views/home/banner')
          },
          {
            path: 'friend',
            name: '友链管理',
            component: () => import('../views/home/friend')
          },
        ]
      },
      {
        path: '/product',
        name: '全部产品',
        redirect: '/product/series',
        component: () => import('../views/product/index'),
        children: [
          {
            path: 'list',
            name: '产品管理',
            component: () => import('../views/product/list'),
            meta: {
              showFather: true
            },
            children: [
              {
                path: 'add',
                name: '新增产品',
                component: () => import('../views/product/detail'),
                meta: {
                  showFather: false
                }
              },
              {
                path: 'edit/:productId',
                name: '编辑产品',
                props: true,
                component: () => import('../views/product/detail'),
                meta: {
                  showFather: false
                }
              },
            ]
          },
          {
            path: 'series',
            name: '系列管理',
            component: () => import('../views/product/series/index'),
            meta: {
              showFather: true
            },
            children: [
              {
                path: 'add',
                name: '新增系列',
                component: () => import('../views/product/series/detail'),
                meta: {
                  showFather: false
                }
              },
              {
                path: 'edit/:seriesId',
                name: '编辑系列',
                props: true,
                component: () => import('../views/product/series/detail'),
                meta: {
                  showFather: false
                }
              },
            ]
          },
          {
            path: 'subject',
            name: '产品专题',
            component: () => import('../views/product/subject'),
          },
        ],
      },
      {
        path: '/case',
        name: '应用案例',
        redirect: '/case/list',
        component: () => import('../views/case/index'),
        children: [
          {
            path: 'list',
            name: '案例管理',
            component: () => import('../views/case/list'),
            meta: {
              showFather: true
            },
            children: [
              {
                path: 'add',
                name: '新增案例',
                meta: {
                  showFather: false,
                },
                component: () => import('../views/case/detail'),
              }, {
                path: 'edit/:caseId',
                name: '编辑案例',
                props: true,
                meta: {
                  showFather: false
                },
                component: () => import('../views/case/detail'),
              },
            ]
          },
          {
            path: 'subject',
            name: '案例专题',
            component: () => import('../views/case/subject'),
          },
        ]
      },
      {
        path: '/support',
        name: '技术支持',
        redirect: '/support/category',
        component: () => import('../views/support/index'),
        children: [
          {
            path: 'list',
            name: '文章管理',
            component: () => import('../views/support/list'),
            meta: {
              showFather: true
            },
            children: [
              {
                path: 'add',
                name: '新增文章',
                meta: {
                  showFather: false,
                },
                component: () => import('../views/support/detail'),
              }, {
                path: 'edit/:supportId',
                name: '编辑文章',
                props: true,
                meta: {
                  showFather: false
                },
                component: () => import('../views/support/detail'),
              },
            ]
          },
          {
            path: 'category',
            name: '分类管理',
            component: () => import('../views/support/category/index'),
            meta: {
              showFather: true
            },
            children: [
              {
                path: 'add',
                name: '新增分类',
                component: () => import('../views/support/category/detail'),
                meta: {
                  showFather: false
                },
              }, {
                path: 'edit/:categoryId',
                name: '编辑分类',
                props: true,
                component: () => import('../views/support/category/detail'),
                meta: {
                  showFather: false
                },
              }
            ]
          },
        ]
      },
      {
        path: '/news',
        name: '企业资讯',
        redirect: '/news/list',
        component: () => import('../views/news/index'),
        children: [
          {
            path: 'list',
            name: '资讯管理',
            component: () => import('../views/news/list'),
            meta: {
              showFather: true
            },
            children: [
              {
                path: 'add',
                name: '新增资讯',
                meta: {
                  showFather: false
                },
                component: () => import('../views/news/detail'),
              }, {
                path: 'edit/:newsId',
                name: '编辑资讯',
                props: true,
                meta: {
                  showFather: false
                },
                component: () => import('../views/news/detail'),
              },
            ]
          },
          {
            path: 'subject',
            name: '资讯专题',
            component: () => import('../views/news/subject'),
          },
        ]
      },
      {
        path: '/about',
        name: '关于我们',
        redirect: '/about/list',
        component: () => import('../views/about/index'),
        children: [
          {
            path: 'list',
            name: '文章管理',
            component: () => import('../views/about/list'),
            meta: {
              showFather: true
            },
            children: [
              {
                path: 'add',
                name: '新增文章',
                component: () => import('../views/about/detail'),
                meta: {
                  showFather: false
                },
              }, {
                path: 'edit/:aboutId',
                name: '编辑文章',
                props: true,
                component: () => import('../views/about/detail'),
                meta: {
                  showFather: false
                },
              },
            ]
          }
        ]
      },
      {
        path: '/404',
        name: '找不到',
        component: () => import('../views/404')
      },
      {
        path: '/link',
        name: '链接转换',
        component: () => import('../views/Link')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    NProgress.start()
    next('/404')
    NProgress.done()
  } else {
    NProgress.start()
    next()
    NProgress.done()
  }
})

export default router
